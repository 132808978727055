.clear-filter[filter-color="blue"] {
  @include linear-gradient(rgba(204, 204, 204, 0) 20%, rgba(29, 0, 0, 0.75) 90%);
}
[data-background-color="blue"]{
    background-color: $info-color;
}
.section-nucleo-icons .icons-container i:nth-child(6) {
  color: $info-color;
}
.page-header{
  &.page-header-small{
    min-height: 40vh;
    max-height: 440px;
  }
}
