// display flex was causing errors
.carousel-item-next,
.carousel-item-prev,
.carousel-item.active{
    display: block;
}

.carousel{
    .carousel-inner{
        box-shadow: $box-shadow-raised;
        border-radius: $border-radius-small;
    }

    .card {
        max-width: 650px auto;
        margin: 60px auto;
        max-height: max-content;
        align-content: center;
        display: block;
    }
}
